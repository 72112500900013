<template>
  <div>
    <v-form>
      <p class="mb-3">
        A. Below, upload a drawing of the BEST Lewis structure by adding lone pairs of electrons as
        dots and bonds as lines. Indicate all non-zero formal charges.
      </p>
      <p>
        <img :src="imageOption" style="max-width: 375px" alt="image of a skeletal structure" />
      </p>
      <p class="mb-n3"><b>Accepted file types</b>: JPG, JPEG, and PNG</p>

      <v-file-input v-model="attachments" multiple />

      <p class="mb-3">What is the molecular shape of the atom circled in red?</p>
      <v-radio-group v-model="inputs.multiChoiceAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in multiChoiceOptionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUBC_SB_Quiz1_1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multiChoiceAnswer: null,
      },
      attachments: [],
      optionsB: [
        {
          text: 'Linear',
          value: 'a',
        },
        {
          text: 'Trigonal Planar',
          value: 'b',
        },
        {
          text: 'Tetrahedral',
          value: 'c',
        },
        {
          text: 'Bent',
          value: 'd',
        },
        {
          text: 'Trigonal pyramidal',
          value: 'e',
        },
      ],
    };
  },
  computed: {
    imageNumber() {
      return this.taskState.getValueBySymbol('imageNumber').content;
    },
    imageOption() {
      switch (this.imageNumber.number) {
        case 1:
          return '/img/assignments/ChemUBC_SB_Quiz1Quiz1Option1.png';
        case 2:
          return '/img/assignments/ChemUBC_SB_Quiz1Quiz1Option2.png';
        case 3:
          return '/img/assignments/ChemUBC_SB_Quiz1Quiz1Option3.png';
        case 4:
          return '/img/assignments/ChemUBC_SB_Quiz1Quiz1Option4.png';
        case 5:
          return '/img/assignments/ChemUBC_SB_Quiz1Quiz1Option5.png';
      }
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
    multiChoiceOptionsShuffled() {
      return seededShuffle(this.optionsB, this.seed);
    },
  },
};
</script>
